<template>
  <main>
    <about-telegram
      v-if="isTelegramPopupShow"
      @close="closeTelegramPopup"
    />
    <header class="list-layout-header">
      <router-link
        to="/"
        class="list-layout-header__logo"
      >
        <logo show-incognito />
      </router-link>

      <div
        v-if="isAuth"
        class="list-layout-header__controls"
      >
        <slot name="controls">
          <!--          <icon-btn
            class="list-layout__balance"
            :size="Size.S"

            @click="$router.push({ name: 'profile-wallet' })"
          >
            <icon
              :name="IconName.Wallet"
              :type="IconType.Fill"
            />
            {{ formattedBalance }}
          </icon-btn>-->

          <icon-btn
            class="list-layout__control"
            :size="Size.S"
            :color="MainColor.Grey4"

            @click="$router.push({ name: 'profile-settings' })"
          >
            <icon
              :type="IconType.Fill"
              :name="IconName.Settings"
              :size="Size.S"
            />
          </icon-btn>

          <icon-btn
            class="list-layout__control"
            :size="Size.S"
            :color="MainColor.Grey4"

            @click="$router.push({ name: 'catalog-filter' })"
          >
            <icon
              :type="IconType.Contour"
              :name="IconName.Settings"
              :size="Size.S"
            />
            <template #marker>
              <div
                v-if="hasFilter"
                class="dot"
              />
            </template>
          </icon-btn>

          <icon-btn
            class="list-layout__control"
            :size="Size.S"
            :color="MainColor.Grey4"

            @click="$router.push({ name: SubscriptionRoutesName.Subscriptions })"
          >
            <icon
              :type="IconType.Fill"
              :name="IconName.Email"
              :size="Size.S"
            />
          </icon-btn>

          <icon-btn
            class="list-layout__control"
            :size="Size.S"
            :color="MainColor.Grey4"
            @click="handleFavoriteClick"
          >
            <icon
              :type="IconType.Fill"
              :name="IconName.Heart"
              :size="Size.S"
              :color="isFavoritePage ? MainColor.Red : MainColor.Grey4"
            />
          </icon-btn>
          <notification-icon />
        </slot>
      </div>
    </header>

    <section
      v-if="hasHeader"
      class="list-layout-info"
    >
      <slot name="header" />
    </section>

    <app-notifications v-if="isAuth && showNotifications" />

    <section
      v-if="hasInfo"
      class="list-layout-info"
    >
      <slot name="info" />
    </section>

    <section>
      <slot name="body" />
    </section>
  </main>
</template>

<script lang="ts" src="./ListLayout.ts"></script>
<style lang="scss" src="./list-layout.scss"></style>
