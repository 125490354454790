import { computed, defineComponent, ref } from 'vue';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';

import sanitizeHtml from 'sanitize-html';

const MetaPageCard = defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isActive = ref(false);

    const limitedContent = props.content.substring(0, 100);
    const sanitizedContent = computed(() => sanitizeHtml(isActive.value ? props.content : limitedContent, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
      allowedAttributes: { img: ['src'] },
      allowedSchemes: ['data', 'http', 'https'],
    }));

    function toggle() {
      isActive.value = !isActive.value;
    }

    return {
      IconType,
      IconName,
      Size,

      sanitizedContent,

      isActive,
      toggle,
    };
  },
});

export default MetaPageCard;
