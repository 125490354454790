<template>
  <div class="main-page-balance">
    <span class="main-page-balance__text">
      {{ $t('pages.main.mainPageBalance.balance') }}
    </span>
    <span class="main-page-balance__balance">
      {{ formatCurrency(balance) }}
    </span>
    <btn
      class="main-page-balance__button"
      :to="{ name: RouteNames.ProfileWallet }"
    >
      {{ $t('pages.main.mainPageBalance.refill') }}
    </btn>
  </div>
</template>

<script lang="ts" src="./MainPageBalance.ts" />
<style lang="scss" src="./mainPageBalance.scss" />
