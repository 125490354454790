<template>
  <form
    novalidate
    class="main-page-search"
    @submit.prevent="onSubmit"
  >
    <txt
      :type="TxtType.Heading3"
    >
      {{ lookingForTitle }}
    </txt>

    <template v-if="!isAuth">
      <div class="main-page-search-selector main-page-search__selector">
        <div
          class="main-page-search-selector__button main-page-search-selector__button--left"
          :class="{ 'main-page-search-selector__button--active': model.gender === Sex.Male }"
          @click="handleSelector(Sex.Male)"
        >
          {{ $t(`pages.main.${Sex.Male}`) }}
        </div>
        <div
          class="main-page-search-selector__button main-page-search-selector__button--right"
          :class="{ 'main-page-search-selector__button--active': model.gender === Sex.Female }"
          @click="handleSelector(Sex.Female)"
        >
          {{ $t(`pages.main.${Sex.Female}`) }}
        </div>
      </div>

      <txt>
        {{ $t('pages.main.age') }}
      </txt>
    </template>

    <div class="main-page-search-age main-page-search__age">
      <textfield
        ref="minAgeRef"
        v-model.number="model.age.from"
        :lt="$t('pages.form.from')"
        :rt="$t('pages.form.years')"
        :rules="[rules.minAge]"
        inputmode="numeric"
        class="main-page-search-age__left"
      />

      <textfield
        ref="maxAgeRef"
        v-model.number="model.age.to"
        :lt="$t('pages.form.to')"
        :rt="$t('pages.form.years')"
        :rules="[rules.maxAge]"
        inputmode="numeric"
        class="main-page-search-age__right"
      />
    </div>

    <btn
      action-type="submit"
      w="100%"
      center
    >
      {{ $t('pages.main.search') }}
    </btn>
  </form>
</template>

<script lang="ts" src="./MainPageSearch.ts" />
<style lang="scss" src="./mainPageSearch.scss" />
