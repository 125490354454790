<template>
  <div class="tiles-photos-empty-avatar">
    <icon
      class="tiles-photos-empty-avatar__icon"
      :name="iconName"
      :type="IconType.Big"
      :color="MainColor.Grey5"
    />
  </div>
</template>

<script lang="ts" src="./EmptyAvatar.ts" />
<style lang="scss" src="./emptyAvatar.scss" />
