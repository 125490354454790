import { defineComponent, h, ref, watch, Teleport, cloneVNode } from 'vue';
import { useI18n } from 'vue-i18n';

import './prompt.scss';

export default defineComponent({
  inheritAttrs: false,

  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: '',
    },
    okTextPath: {
      type: String,
      default: 'prompt.ok',
    },
    cancelTextPath: {
      type: String,
      default: 'prompt.cancel',
    },
    hideOk: Boolean,
    hideCancel: Boolean,
    width: Number,
  },

  emits: ['ok', 'update:modelValue'],

  setup(props, { slots, attrs, emit }) {
    const { t } = useI18n();

    const wrapperRef = ref<HTMLElement>();
    const isActive = ref(props.modelValue);

    watch(() => props.modelValue, (val: boolean) => {
      isActive.value = val;
    });

    watch(isActive, (val) => {
      emit('update:modelValue', val);
    });

    function open() {
      isActive.value = true;
    }

    function close() {
      isActive.value = false;
    }

    function genActivator() {
      const node = slots.activator?.() || [];
      return node.map((n) => cloneVNode(n, { onClick: open }));
    }

    return () => [
      h(Teleport, {
        to: '#modals',
      }, isActive.value ? h('div', {
        class: ['prompt', attrs.class],
        onClick: (e: PointerEvent) => {
          const target = e.target as HTMLElement;
          if (wrapperRef.value && target.contains(wrapperRef.value)) {
            close();
          }
        },
      }, h('div', {
        ref: wrapperRef,
        class: 'prompt__wrapper',
        style: {
          width: props.width ? `${props.width}px` : undefined,
        },
      }, [
        props.title ? h('h4', { class: 'prompt__title' }, props.title) : undefined,

        h('div', {
          class: 'prompt__content',
        }, slots.default?.()),

        !props.hideOk && h('button', {
          class: 'prompt__action prompt__action--ok',
          onClick: () => {
            emit('ok');
            close();
          },
        }, t(props.okTextPath)),

        !props.hideCancel && h('button', {
          class: 'prompt__action',
          onClick: close,
        }, t(props.cancelTextPath)),
      ])) : []),

      genActivator(),
    ];
  },
});
