import { defineComponent, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const MetaPage = defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    showFootNote: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const isActive = ref(false);
    const urlPage = ref('');
    const route = useRoute();

    function detectActivePage() {
      const page = route.params.page as string;
      const city: string = Array.isArray(route.params?.city)
          ? route.params.city[0]
          : route.params?.city ?? '';

      urlPage.value = `${props.url}/${city}`;

      if (props.url.includes(page)) {
        isActive.value = true;
      } else {
        isActive.value = false;
      }
    }

    watch(() => route.path, detectActivePage);
    onMounted(detectActivePage);

    return {
      urlPage,
      isActive,
    };
  },
});

export default MetaPage;
