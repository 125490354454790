<template>
  <div
    v-if="users && users.length > 0"
  >
    <tile-photos
      :size="cutUsers.length"
      :users="transformedUsers"
    >
      <template #header>
        <txt
          :type="TxtType.Heading3"
          :weight="TxtWeight.SemiBold"
        >
          {{ title }}
        </txt>
      </template>

      <template
        v-if="isBtnShows || isShowedMore"
        #footer
      >
        <btn
          v-if="isBtnShows"
          :type="BtnType.Secondary"
          w="100%"
          @click="fetchMore"
        >
          {{ $t('pages.main.showMore') }}
        </btn>
        <btn
          v-else-if="isShowedMore"
          :type="BtnType.Secondary"
          :disabled="isFetching"
          w="100%"
          @click="goToCatalog"
        >
          {{ $t('pages.main.goToCatalog') }}
        </btn>
      </template>
    </tile-photos>
  </div>
</template>

<script lang="ts" src="./Photos.ts" />
