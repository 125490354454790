import { defineComponent, reactive, ref } from 'vue';
import { TextfieldStyle } from 'components/Textfield';
import { IconName, IconType } from 'components/Icon';
import { usePrivatePages } from 'composables/privatePages';
import { Nullable } from 'core/types';
import { useI18n } from 'vue-i18n';
import { useAdmin } from 'composables/admin';

export default defineComponent({
  props: {
    pageName: {
      type: String,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { title, content, updatePageContent, fetchPageContent } = usePrivatePages(props.pageName);

    const { isAdmin } = useAdmin();

    const isModalShow = ref(false);
    const openModal = () => {
      isModalShow.value = isAdmin.value && true;
      fetchPageContent();
    };
    const closeModal = () => { isModalShow.value = false; };

    const rules = reactive({
      required: (v: Nullable<string>) => !!v || t('rule.required'),
    });
    const titleRef = ref();

    function validate() {
      const titleValid = titleRef.value.validate();
      if (titleValid) return true;

      titleRef.value.focus();
      return false;
    }

    async function update() {
      if (validate()) {
        await updatePageContent();
        closeModal();
        emit('update');
      }
    }

    return {
      isAdmin,
      isModalShow,
      openModal,
      closeModal,

      rules,
      titleRef,

      title,
      content,
      update,
    };
  },

  data() {
    return {
      TextfieldStyle,
      IconName,
      IconType,
    };
  },
});
