import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { IconName, IconType } from 'components/Icon';
import { Sex } from 'api/users/models/user/enums/Sex';
import type { ICity, IMetapagesResponse, Nullable } from 'core/types';
import MainLayout from 'layouts/MainLayout';
import ListLayout from 'layouts/ListLayout';
import { USER_STORE_KEY, UsersAction, UsersGetter, UsersMutation, useUsersMutation } from 'store/users';
import { METAPAGES_STORE_KEY, MetaPagesGetter } from 'store/metapages';
import { TxtType, TxtWeight } from 'components/Txt';
import { usePages } from 'composables/pages';
import { useStore } from 'vuex';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import { useCity } from 'composables/city';
import { useMetapage, useMetapageData } from 'composables/metapage';
// import { citiesLinksById } from 'core/values';
import { IProfile } from 'api/users/models/user';
import { Size } from 'core/styles';
import MainPageSearch from '../../components/MainPageSearch';
import MainPageBalance from '../../components/MainPageBalance';
import MainPageFooter from '../../components/MainPageFooter';
import MetaPage from '../../components/MetaPage';
import EditModal from '../../components/EditModal';
import Photos from '../../components/Photos';
import IncognitoAlert from '../../components/IncognitoAlert';
import MetaPageCard from '../../components/MetaPageCard';

const Main = defineComponent({
  name: RouteNames.Main,
  components: {
    MainLayout,
    ListLayout,
    EditModal,
    Photos,
    MainPageSearch,
    MainPageFooter,
    MetaPage,
    MetaPageCard,
    MainPageBalance,
    IncognitoAlert,
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);
    const { showError } = useApiErrors();

    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const fetchTopUsers =
      (fetchMore = false) => store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchTopUsers}`, fetchMore).catch((e) => showError(e));
    const fetchNewUsers =
      (fetchMore = false) => store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchNewUsers}`, fetchMore).catch((e) => showError(e));
    const fetchMaleUsers =
      (fetchMore = false) => store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchMaleUsers}`, fetchMore).catch((e) => showError(e));
    const fetchFemaleUsers =
      (fetchMore = false) => store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchFemaleUsers}`, fetchMore).catch((e) => showError(e));

    const newUsers = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.NewUsers}`]);
    const newUsersTotal = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TotalNewUsers}`]);
    const topUsers = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TopUsers}`]);
    const topUsersTotal = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TotalTopUsers}`]);
    const maleUsers = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.MaleUsers}`]);
    const maleUsersTotal = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TotalMaleUsers}`]);
    const femaleUsers = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.FemaleUsers}`]);
    const femaleUsersTotal = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.TotalFemaleUsers}`]);

    const {
      loading: homeLoading,
    } = usePages('home');
    const {
      title: homeTitle,
      content: homeContent,
      fetchPageContent: homeFetchPageContent,
    } = useMetapageData();
    const {
      loading: areaLoading,
      title: areaTitle,
      content: areaContent,
      fetchPageContent: areaFetchPageContent,
    } = usePages('area');
    const isPrerender = 'is_prerender' in router.currentRoute.value.query;
    const isContentOpen = ref(isPrerender);

    const limitedContent = computed(() => {
      if (isContentOpen.value) {
        return homeContent.value;
      }
      const content = homeContent.value.substring(0, 200);
      return `${content}...`;
    });
    function toggleContent() {
      isContentOpen.value = !isContentOpen.value;
    }

    const clearFilterMutation = useUsersMutation(UsersMutation.ClearFilter);
    const cities = computed<ICity[]>(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);
    const citiesLinksById: Record<string, string> = {};
    cities.value.forEach(city => {
      citiesLinksById[city.id.toString()] = city.slug;
    });

    const metapages = computed<IMetapagesResponse[]>(() => store.getters[`${METAPAGES_STORE_KEY}/${MetaPagesGetter.Pages}`]);
    const metapagesMenu: any[] = [];
    metapages.value.forEach(metapage => {
      if (metapage.cities_enabled === false) {
        const city: string = Array.isArray(router.currentRoute.value?.params?.city)
          ? router.currentRoute.value.params.city[0]
          : router.currentRoute.value?.params?.city ?? '';

        if (metapage.url === '/') return;

        const currentPath = router.currentRoute.value.path.replace(new RegExp(city, 'g'), '');
        metapagesMenu.push({
          url: metapage.url,
          title: metapage.title,
          isCurrentPage: metapage.url === currentPath,
        });
      }
    });

    const rules = reactive({
      required: (v: Nullable<string>) => !!v || t('rule.required'),
      select: (v: Nullable<string>) => !!v || t('rule.select'),
    });

    async function goToCatalog() {
      router.push({ name: 'catalog' });
    }

    async function goToCityPage(cityName: string) {
      const cleanedCityName = cityName.replace(/\//g, '');
      let url = '';

      if (!router.currentRoute.value.params.page || router.currentRoute.value.params.page === '/') {
        url = router.resolve({
          name: RouteNames.MainMetaCity,
          params: {
            city: cleanedCityName,
          },
        }).href;
      } else {
        url = router.resolve({
          name: RouteNames.MainMeta,
          params: {
            page: router.currentRoute.value.params.page,
            city: cleanedCityName,
          },
        }).href;
      }

      window.location.assign(url);
    }

    function onCityClick(cityId: number) {
      clearFilterMutation(null);
      store.commit(`${USER_STORE_KEY}/${UsersMutation.SetFilter}`, {
        city_id: cityId,
      });
      goToCityPage(citiesLinksById[cityId]);
    }

    const { currentCity } = useCity();
    const { currentMetapage } = useMetapage();

    onMounted(() => {
      if (currentCity.value?.id) {
        store.commit(`${USER_STORE_KEY}/${UsersMutation.SetFilter}`, {
          city_id: currentCity.value.id,
        });
      }
    });

    return {
      isAuth,
      profile,
      homeLoading,
      limitedContent,
      homeTitle,
      metapagesMenu,
      isContentOpen,
      toggleContent,
      homeFetchPageContent,

      areaLoading,
      areaTitle,
      areaContent,
      areaFetchPageContent,

      cities,
      metapages,
      rules,
      goToCatalog,
      onCityClick,
      currentCity,
      currentMetapage,

      newUsers,
      newUsersTotal,
      topUsers,
      topUsersTotal,
      fetchTopUsers,
      fetchNewUsers,
      maleUsers,
      femaleUsers,
      maleUsersTotal,
      femaleUsersTotal,
      fetchMaleUsers,
      fetchFemaleUsers,

      Sex,
      Size,
      IconName,
      IconType,
      TxtType,
      TxtWeight,
      citiesLinksById,
    };
  },
});

export default Main;
