import { computed, defineComponent } from 'vue';
import { ADMIN_STORE_KEY, AdminGetter } from 'store/admin';
import { useStore } from 'vuex';

const MainPageFooter = defineComponent({
  props: {
    showFootNote: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const isAdmin = computed(() => store.getters[`${ADMIN_STORE_KEY}/${AdminGetter.IsAdmin}`]);

    return {
      isAdmin,
    };
  },
});

export default MainPageFooter;
