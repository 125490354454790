import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { IUser } from 'api/users/models/user';
import { useRouter } from 'vue-router';
import EmptyAvatar from './components/EmptyAvatar';

const TilePhotos = defineComponent({
  components: {
    EmptyAvatar,
  },
  props: {
    users: {
      type: Array as PropType<IUser[]>,
      required: true,
    },
    size: {
      type: Number,
      default: 12,
    },
  },
  setup(props) {
    const router = useRouter();

    function goToProfile(user: IUser) {
      router.push(`/user/${user.id}`);
    }

    // @todo нужна картинка заглушка
    function getPhotoUrl(user: IUser) {
      return user?.photos[0]?.thumbnail || user?.photos[0]?.path || '';
    }

    const cutUsers = computed(() => (props.users.slice(0, props.size)));

    return {
      cutUsers,
      goToProfile,
      getPhotoUrl,
    };
  },
});

export default TilePhotos;
