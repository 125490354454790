<template>
  <footer class="main-page-footer">
    <div class="main-page-footer__links">
      <span class="main-page-footer__link">
        <router-link
          to="/blogs"
          class="main-page-footer__router-link"
        >
          {{ $t('pages.main.blogs') }}
        </router-link>
        <span>&bull;</span>
      </span>
      <span class="main-page-footer__link">
        <router-link
          to="/rules"
          class="main-page-footer__router-link"
        >
          {{ $t('pages.main.rules') }}
        </router-link>
        <span>&bull;</span>
      </span>
      <span class="main-page-footer__link">
        <router-link
          to="/agreement"
          class="main-page-footer__router-link"
        >{{ $t('pages.main.agreement') }}</router-link>
        <span>&bull;</span>
      </span>
      <span class="main-page-footer__link">
        <router-link
          to="/agreement/privacy"
          class="main-page-footer__router-link"
        >{{ $t('pages.main.agreementPrivacy') }}</router-link>
        <span>&bull;</span>
      </span>
      <span class="main-page-footer__link">
        <router-link
          to="/technical-support"
          class="main-page-footer__router-link"
        >{{ $t('pages.main.technicalSupport') }}</router-link>
        <span v-if="isAdmin">&bull;</span>
      </span>
      <span
        v-if="isAdmin"
        class="main-page-footer__router-link"
      >
        <router-link
          to="/admin-panel"
          class="main-footer-page__router-link"
        >{{ $t('pages.main.adminPanel') }}</router-link>
      </span>
    </div>
    <div v-if="showFootNote">{{ $t('pages.main.footnote') }}</div>
  </footer>
</template>

<script lang="ts" src="./MainPageFooter.ts" />
<style lang="scss" src="./mainPageFooter.scss" />
