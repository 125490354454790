import { ref } from 'vue';
import { useStore } from 'vuex';
import sanitizeHtml from 'sanitize-html';
import { IUpdatePageContent } from 'api/adminService';
import { ADMIN_STORE_KEY, AdminAction } from 'store/admin';
import { useApiErrors } from 'composables/apiErrors';

export function usePrivatePages(page: string) {
  const store = useStore();
  const loading = ref(false);
  const { showError } = useApiErrors();

  const title = ref('');
  const content = ref('');

  async function fetchPageContent() {
    try {
      loading.value = true;
      const item = await store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.FetchPage}`, page);

      title.value = item?.title ? sanitizeHtml(item?.title) : '';
      content.value = item?.content ? sanitizeHtml(item?.content) : '';
    } catch (e) {
      showError(e);
    } finally {
      loading.value = false;
    }
  }

  async function updatePageContent() {
    const model: IUpdatePageContent = {
      title: title.value,
      content: content.value,
    };
    try {
      await store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.UpdatePage}`, { page, model });
    } catch (e) {
      showError(e);
    }
  }

  return {
    loading,
    title,
    content,
    fetchPageContent,
    updatePageContent,
  };
}
