import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import sanitizeHtml from 'sanitize-html';
import { PAGES_STORE_KEY, PagesGetter, PagesAction } from 'store/pages';
import { IPagesResponse } from 'core/types';
import { useApiErrors } from 'composables/apiErrors';

export function usePages(page: string) {
  const store = useStore();
  const loading = ref(false);
  const { showError } = useApiErrors();

  const item = computed<IPagesResponse | undefined>(() =>
    store.getters[`${PAGES_STORE_KEY}/${PagesGetter.Pages}`]?.[page]);
  const title = computed(() => (item.value?.title ? sanitizeHtml(item.value.title) : ''));
  const content = computed(() => (item.value?.content ? sanitizeHtml(item.value.content) : ''));

  async function fetchPageContent() {
    try {
      loading.value = true;
      await store.dispatch(`${PAGES_STORE_KEY}/${PagesAction.GetPage}`, page);
    } catch (e) {
      showError(e);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    title,
    content,
    fetchPageContent,
  };
}
