import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TxtType } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { UsersGetter, useUsersGetter, USER_STORE_KEY } from 'store/users';
import { SubscriptionRoutesName } from 'router/names';
import { useStore } from 'vuex';
import { useCurrency } from 'composables/currency';
import AppNotifications from 'components/AppNotifications';
import { AppNotificationColor, useAppNotifications } from 'composables/appNotifications';
import { useI18n } from 'vue-i18n';
import AboutTelegram from './components/AboutTelegram';

const tgSymbol = Symbol('telegram');

const ListLayout = defineComponent({
  components: {
    AppNotifications,
    AboutTelegram,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showNotifications: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { slots }) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { formatCurrency } = useCurrency();

    const balance = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`].balance);
    const formattedBalance = computed(() => formatCurrency(balance.value));

    const hasFilter = computed(() => useUsersGetter<boolean>(UsersGetter.HasFilter));

    const hasInfo = computed(() => !!slots?.info);
    const hasHeader = computed(() => !!slots?.header);
    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);

    const isFavoritePage = computed(() => route.name === 'favorites');

    function handleFavoriteClick() {
      if (isFavoritePage.value) {
        router.push({ name: 'catalog' });
      } else {
        router.push({ name: 'favorites' });
      }
    }

    const { createAppNotification, isAppNotificationExist } = useAppNotifications();
    const isTelegramPopupShow = ref(false);
    const openTelegramPopup = () => { isTelegramPopupShow.value = true; };
    const closeTelegramPopup = () => {
      isTelegramPopupShow.value = false;
      createTgNotification();
    };

    createTgNotification();

    function createTgNotification() {
      const profile = store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`];
      if (store.getters[`${USER_STORE_KEY}/${UsersGetter.EmailNotModerated}`]
        || !profile || profile.tg_subscribed) {
        return;
      }

      if (isAppNotificationExist(tgSymbol)) return;
      createAppNotification({
        message: t('appNotifications.telegram.message'),
        color: AppNotificationColor.Yellow,
        type: tgSymbol,
        action: {
          text: t('appNotifications.telegram.action'),
          noHide: true,
          onClick: openTelegramPopup,
        },
      });
    }

    return {
      isAuth,
      hasFilter,
      hasInfo,
      hasHeader,
      isFavoritePage,
      handleFavoriteClick,
      formattedBalance,

      isTelegramPopupShow,
      openTelegramPopup,
      closeTelegramPopup,

      IconName,
      IconType,
      TxtType,
      MainColor,
      Size,
      SubscriptionRoutesName,
    };
  },
});

export default ListLayout;
