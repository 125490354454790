import { computed, ref } from 'vue';

export enum AppNotificationColor {
  Green = 'green',
  Yellow = 'yellow',
}

export interface IAppNotificationAction {
  text?: string;
  noHide?: boolean;
  onClick?: () => void;
}

export interface IAppNotification {
  id: number;
  color?: AppNotificationColor
  message?: string
  action?: IAppNotificationAction
  type?: string | symbol
}

export interface ICreateAppNotification {
  color: IAppNotification['color'];
  message: IAppNotification['message'];
  action: IAppNotification['action'];
  type: IAppNotification['type'];
}

let id = 0;

const appNotifications = ref<IAppNotification[]>([]);

function createAppNotification(notification: ICreateAppNotification): number {
  id++;
  appNotifications.value.unshift({
    id,
    ...notification,
  });
  return id;
}

function removeAppNotification(id: number) {
  const index = appNotifications.value.findIndex((appNotification) => appNotification.id === id);
  if (index >= 0) {
    appNotifications.value.splice(index, 1);
  }
}

function clearAppNotifications() {
  appNotifications.value = [];
}

function isAppNotificationExist(type: string | symbol) {
  return appNotifications.value.some((notification) => notification.type === type);
}

export function useAppNotifications() {
  return {
    appNotifications: computed(() => appNotifications.value),
    createAppNotification,
    removeAppNotification,
    clearAppNotifications,
    isAppNotificationExist,
  };
}
