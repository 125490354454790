<template>
  <incognito-popup
    :model-value="popupShow"
    @update:modelValue="popupShow = $event"
    @bought="onBought"
  />
  <div
    v-if="isShow"
    class="incognito-alert"
  >
    <div>
      <p
        ref="textRef"
        class="incognito-alert__text"
      >
        {{ $t('pages.main.incognitoAlert.title') }}
        <strong class="incognito-alert__strong">{{ $t('pages.main.incognitoAlert.name') }}</strong>
      </p>
      <p
        class="incognito-alert__about"
        @click="popupShow = true"
      >
        {{ $t('pages.main.incognitoAlert.about') }}
      </p>
    </div>
    <icon-btn
      class="incognito-alert__icon"
      :size="Size.S"
      @click="hideAlert"
    >
      <icon
        :name="IconName.Crosses"
        :type="IconType.Contour"
        :size="Size.Xxs"
      />
    </icon-btn>
  </div>
</template>

<script lang="ts" src="./IncognitoAlert.ts" />
<style lang="scss" src="./incognitoAlert.scss" />
