import { defineComponent, h } from 'vue';
import { useI18n } from 'vue-i18n';
import Icon, { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';

import type { PropType } from 'vue';

import './alert.scss';

const Alert = defineComponent({
  props: {
    color: {
      type: String as PropType<'primary' | 'success' | 'warn' | 'error'>,
      default: 'primary',
    },
    actionText: {
      type: String,
      default: undefined,
    },
    closeAction: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['actionClick', 'closeClick'],

  setup(props, { slots, emit }) {
    const { t } = useI18n();

    function genIcon() {
      const name = props.color === 'success'
        ? IconName.Checked
        : props.color === 'warn'
          ? IconName.BellAlt
          : undefined;

      if (!name) return undefined;

      return h('div', {
        class: 'alert__icon',
      }, h(Icon, {
        type: IconType.Fill,
        name,
        color: MainColor.White,
        size: Size.Xxs,
      }));
    }

    function genActions() {
      if (!props.actionText && !props.closeAction) return undefined;

      return h('div', { class: 'alert__actions' }, [
        props.actionText ? h('button', { onClick: () => emit('actionClick') }, props.actionText) : undefined,
        props.closeAction ? h('button', { class: 'alert__actions__close', onClick: () => emit('closeClick') }, t('components.alert.hide')) : undefined,
      ]);
    }

    return () => h('div', {
      class: ['alert', `alert--${props.color}`],
    }, [
      genIcon(),
      h('div', [
        h('div', { class: 'alert__content' }, slots.default?.()),
        genActions(),
      ]),
    ]);
  },
});

export default Alert;
