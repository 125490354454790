<template>
  <main-layout>
    <incognito-alert v-if="isAuth && profile && profile.gender === Sex.Male" />
    <list-layout
      :title="$t('pages.catalog.title')"
      class="main"
      show-notifications
    >
      <template #header>
        <main-page-balance v-if="isAuth" />
      </template>

      <template #info>
        <main-page-search @submit="goToCatalog" />
      </template>

      <template #body>
        <no-prerender>
          <is-majority-wrapper>
            <div
              v-if="isAuth"
              class="main-body__photos"
            >
              <photos
                :users="topUsers"
                :total="topUsersTotal"
                :title="$t('pages.main.topProfiles')"
                sort="top"
                @more="fetchTopUsers(true)"
              />

              <photos
                :users="newUsers"
                :total="newUsersTotal"
                :title="$t('pages.main.newProfiles')"
                sort="new"
                @more="fetchNewUsers(true)"
              />
            </div>
            <div
              v-else
              class="main-body__photos"
            >
              <photos
                :users="femaleUsers"
                :total="femaleUsersTotal"
                :title="$t('pages.main.femaleProfiles')"
                :gender="Sex.Female"
                @more="fetchFemaleUsers(true)"
              />
              <photos
                :users="maleUsers"
                :total="maleUsersTotal"
                :title="$t('pages.main.maleProfiles')"
                :gender="Sex.Male"
                @more="fetchMaleUsers(true)"
              />
            </div>
          </is-majority-wrapper>
        </no-prerender>

        <div class="main-body">
          <div class="main-body__edit-modal-wrapper">
            <txt
              :type="TxtType.Heading1"
              class="main-body__h1"
            >
              <div v-html="homeTitle" />
              <!-- {{ $t('pages.main.lovistaIs.title') }}
              {{ currentCity && $t('pages.main.lovistaIs.inCity', { city: currentCity.name }) }} -->
            </txt>
            <edit-modal
              page-name="home"
              @update="homeFetchPageContent"
            />
          </div>
          <div
            class="main-body-content"
            v-html="limitedContent"
          />
          <button
            :class="['main-body-content__button', isContentOpen && 'main-body-content__button-active']"
            @click="toggleContent"
          >
            {{ isContentOpen ? $t('pages.blog.readMoreHide') : $t('pages.blog.readMore') }}

            <icon
              :type="IconType.Contour"
              :name="IconName.ArrowUncover"
              :size="Size.Xs"
            />
          </button>

          <ul class="main-body__meta-pages">
            <meta-page
              v-for="metaPage in metapagesMenu"
              :url="metaPage.url"
              :label="metaPage.title"
            />
          </ul>

          <div class="mt-4 main-body__edit-modal-wrapper">
            <txt
              :type="TxtType.Heading3"
              v-html="areaTitle"
            />
            <edit-modal
              page-name="area"
              @update="areaFetchPageContent"
            />
          </div>
          <div v-html="areaContent" />

          <div class="main-page-cities">
            <a
              v-for="(item, i) in cities"
              :key="i"
              :href="citiesLinksById[item.id]"
              class="main-page-cities__link"
              @click.prevent="onCityClick(item.id)"
            >
              {{ item.name }}
            </a>
          </div>
        </div>

        <main-page-footer />
      </template>
    </list-layout>
  </main-layout>
</template>

<script lang="ts" src="./Main.ts"></script>
<style lang="scss" src="./main.scss"></style>
