import { computed, defineComponent, ref } from 'vue';
import Prompt from 'components/Prompt';
import { TxtType } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { useI18n } from 'vue-i18n';
import { useCurrency } from 'composables/currency';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useStore } from 'vuex';
import { useApiErrors } from 'composables/apiErrors';
import BG from './assets/bg.raw.svg';

const IncognitoPopup = defineComponent({
  components: {
    Prompt,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'bought'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { showError } = useApiErrors();

    const { formatCurrency } = useCurrency();

    const model = computed({
      get: () => props.modelValue,
      set: (val) => emit('update:modelValue', val),
    });

    function back() {
      model.value = false;
    }

    const Cost1 = useServiceSettings(ServiceNames.Incognito1, true);
    const Cost6 = useServiceSettings(ServiceNames.Incognito6, true);

    const options = ref([{
      value: 1,
      title: t('pages.main.incognitoPopup.price', { price: Cost1.value.price, n: 1 }, 1),
    },
      {
        value: 6,
        title: t('pages.main.incognitoPopup.price', { price: Cost6.value.price, n: 6 }, 6),
      },
    ]);
    const radioModel = ref(options.value[1]);

    const price = computed(() => {
      if (radioModel.value.value === 1) return Cost1.value.price;
      return Cost6.value.price;
    });

    const isShowPrompt = ref(false);
    function handleBuy() {
      isShowPrompt.value = true;
    }

    async function buyIncognito() {
      if (!radioModel.value || !radioModel.value.value) return;
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.BuyIncognito}`, radioModel.value.value);
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.main.incognitoPopup.success'),
        });
        isShowPrompt.value = false;
        model.value = false;
        emit('bought', radioModel.value.value);
      } catch (e) {
        showError(e);
      }
    }

    return {
      model,
      isShowPrompt,
      price,
      back,
      options,
      radioModel,
      formatCurrency,
      buyIncognito,
      handleBuy,
      Cost1,
      Cost6,

      BG,
      TxtType,
      IconName,
      IconType,
    };
  },
});

export default IncognitoPopup;
