import { computed, defineComponent, ref } from 'vue';
import { PropType } from '@vue/runtime-core';
import { IUser, IUserFilter, IUserOnMainPage, Sex } from 'api/users/models/user';
import { useRouter } from 'vue-router';
import { BtnType } from 'components/Btn';
import { TxtType, TxtWeight } from 'components/Txt';
import TilePhotos from 'components/TilePhotos';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersMutation } from 'store/users';
import { RouteNames } from 'router/names';
import { useFetching } from 'composables/fetching';

const Photos = defineComponent({
  components: {
    TilePhotos,
  },
  props: {
    users: {
      type: Array as PropType<IUserOnMainPage[]>,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    gender: {
      type: String as PropType<Sex>,
      required: false,
      default: undefined,
    },
    sort: {
      type: String as PropType<IUserFilter['sort']>,
      required: false,
      default: undefined,
    },
  },
  emits: ['more'],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const isShowedMore = ref(false);
    const isBtnShows = computed(() => !isShowedMore.value && props.total > 12);

    function fetchMore() {
      isShowedMore.value = true;
      emit('more');
    }

    const { isFetching, fetch } = useFetching();
    async function goToCatalog() {
      const newFilter: IUserFilter = {};
      if (props.gender) newFilter.gender = props.gender;
      if (props.sort) newFilter.sort = props.sort;
      store.commit(`${USER_STORE_KEY}/${UsersMutation.ClearFilter}`);
      store.commit(`${USER_STORE_KEY}/${UsersMutation.SetFilter}`, newFilter);
      fetch(router.push({
        name: RouteNames.Catalog,
      }));
    }

    function goToProfile(user: IUser) {
      router.push(`/user/${user.id}`);
    }

    // @todo нужна картинка заглушка
    function getPhotoUrl(user: IUser) {
      return user?.photos[0]?.path || '';
    }

    const cutUsers = computed(() => {
      if (!isShowedMore.value) {
        return props.users.slice(0, 12);
      }
      return props.users.slice(0, 24);
    });

    const transformedUsers = computed(() => cutUsers.value.map((user) => ({
      id: user.id,
      photo: {
        thumbnail: user.thumbnail,
      },
      photos: [{
        thumbnail: user.thumbnail,
      }],
    })));

    return {
      isFetching,
      fetchMore,
      goToProfile,

      cutUsers,
      transformedUsers,
      isBtnShows,
      isShowedMore,
      getPhotoUrl,

      goToCatalog,

      TxtType,
      TxtWeight,
      BtnType,
    };
  },
});

export default Photos;
