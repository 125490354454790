<template>
  <popup :show="model">
    <icon-btn
      class="about-paid-services__back"
      @click="back"
    >
      <icon
        :name="IconName.ArrowBack"
        :type="IconType.Contour"
      />
    </icon-btn>
    <txt
      :type="TxtType.Heading2"
      class="mb-2"
    >
      {{ $t('pages.main.incognitoPopup.title') }}
    </txt>

    <div
      class="text-center"
      v-html="BG"
    />

    <p class="incognito-popup__desc">
      {{ $t('pages.main.incognitoPopup.description') }}
    </p>

    <radio-group
      v-model="radioModel"
      :values="options"
      :border="false"
    >
      <template #default="{ value }">
        {{ value.title }}
      </template>
    </radio-group>

    <btn
      style="margin-top: 20px"
      @click="handleBuy"
    >
      {{ $t('pages.main.incognitoPopup.buy', { price: formatCurrency(price) }) }}
    </btn>
  </popup>

  <prompt
    v-model="isShowPrompt"
    :width="293"
    :title="$t('pages.main.incognitoPopup.promptTitle', { price: formatCurrency(price) })"
    ok-text-path="pages.main.incognitoPopup.promptOk"
    @ok="buyIncognito"
  />
</template>

<script lang="ts" src="./IncognitoPopup.ts" />
<style lang="scss" src="./incognitoPopup.scss" />
