<template>
  <div
    class="tile-photos"
  >
    <div
      v-if="$slots.header"
      class="tile-photos__header"
    >
      <slot name="header" />
    </div>
    <div v-if="users && users.length" class="tile-photos__wrapper">
      <div
        v-for="user in cutUsers"
        :key="user.id"
        class="tile-photos__item"
        @click="goToProfile(user)"
      >
        <img
          v-if="getPhotoUrl(user)"
          :src="getPhotoUrl(user)"
          class="tile-photos__img"
        >
        <empty-avatar
          v-else-if="user.gender"
          class="tile-photos__img"
          :sex="user.gender"
        />
      </div>
    </div>
    <div v-else-if="$slots.empty" class="tile-photos__empty">
      <slot name="empty" />
    </div>
    <div
      v-if="$slots.footer"
      class="tile-photos__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts" src="./TilePhotos.ts" />
<style lang="scss" src="./tilePhotos.scss" />
