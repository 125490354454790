<template>
  <modal
    :header="{
      title: $t('admin.edit.modalTitle'),
    }"
    :show="isModalShow"
    @close="closeModal"
  >
    <div class="edit-modal_modal-content">
      <textfield
        v-model="title"
        ref="titleRef"
        :rules="[rules.required]"
        class="edit-modal_modal-field"
        :placeholder="$t('admin.edit.title')"
        :style="TextfieldStyle.Primary"
      />
      <textfield
        v-model="content"
        class="edit-modal_modal-field"
        textarea
        :rows="14"
        :placeholder="$t('admin.edit.content')"
        :style="TextfieldStyle.Primary"
      />
      <btn @click="update" class="edit-modal_modal-btn">{{ $t('admin.edit.save') }}</btn>
    </div>
  </modal>
  <icon-btn
    v-if="isAdmin"
    @click="openModal"
  >
    <icon
      :name="IconName.Pencil"
      :type="IconType.Fill"
      size="s"
      color="grey-5"
    />
  </icon-btn>
</template>

<script lang="ts" src="./EditModal.ts" />
<style lang="scss" src="./edit-modal.scss" />
