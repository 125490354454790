import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { RouteNames } from 'router/names';
import { useCurrency } from 'composables/currency';

const MainPageBalance = defineComponent({
  setup() {
    const store = useStore();
    const { formatCurrency } = useCurrency();

    const balance = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.balance ?? 0);

    return {
      formatCurrency,
      balance,
      RouteNames,
    };
  },
});

export default MainPageBalance;
