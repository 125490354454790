import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { Sex } from 'api/users/models/user';
import { IconName, IconType } from 'components/Icon';
import { MainColor } from 'core/styles';

const EmptyAvatar = defineComponent({
  props: {
    sex: {
      type: String as PropType<Sex>,
      required: true,
    },
  },
  setup(props) {
    const iconName = computed(() => (props.sex === Sex.Female ? IconName.Female : IconName.Male));

    return {
      iconName,

      IconType,
      MainColor,
    };
  },
});

export default EmptyAvatar;
