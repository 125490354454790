import { defineComponent, h } from 'vue';
import { AppNotificationColor, IAppNotification, useAppNotifications } from 'composables/appNotifications';
import Alert from '../Alert';

export default defineComponent({
  setup() {
    const { appNotifications, removeAppNotification } = useAppNotifications();

    function onClick(item: IAppNotification) {
      removeAppNotification(item.id);
      if (item.action?.onClick) {
        item.action?.onClick();
      }
    }

    return () => h('div', appNotifications.value.map(item => h(Alert, {
      class: 'm-4',
      color: item.color === AppNotificationColor.Green ? 'success' : 'warn',
      actionText: item.action?.text || undefined,
      closeAction: !item.action?.noHide,
      onActionClick: () => onClick(item),
      onCloseClick: () => removeAppNotification(item.id),
    }, {
      default: () => item.message,
    })));
  },
});
