import { useStore } from 'vuex';
import { computed } from 'vue';
import { ADMIN_STORE_KEY, AdminGetter } from 'store/admin';

export function useAdmin() {
  const store = useStore();

  const isAdmin = computed(() => store.getters[`${ADMIN_STORE_KEY}/${AdminGetter.IsAdmin}`]);

  return {
    isAdmin,
  };
}
