<template>
  <li class="meta-page">
    <a
      :class="['meta-page__link', isActive && 'meta-page__link-active']"
      :href="$router.resolve({path: urlPage}).href"
    >
      {{ label }}
    </a>
  </li>
</template>

<script lang="ts" src="./MetaPage.ts" />
<style lang="scss" src="./metaPage.scss" />
