import { computed, defineComponent, reactive, ref } from 'vue';
import { isNumber } from 'core/helpers';
import { useI18n } from 'vue-i18n';
import { Nullable } from 'core/types';
import { USER_STORE_KEY, UsersGetter, UsersMutation, useUsersGetter } from 'store/users';
import { useStore } from 'vuex';
import { IProfile, IUserFilter, Sex } from 'api/users/models/user';
import { IDefaultUserFilter } from 'store/users/interfaces/IDefaultUserFilter';
import { TxtType } from 'components/Txt';
import { BtnType } from 'components/Btn';
import { IFromTo } from 'api/users/models/user/interfaces/IFromTo';

type Model = {
  age: IFromTo,
  gender: Nullable<Sex>,
}

const MainPageSearch = defineComponent({
  emits: ['submit'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);

    const defaultFilters = computed<IDefaultUserFilter>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.FilterDefaults}`]);
    const filters = computed<Nullable<IUserFilter>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Filter}`]);

    const minAgeValue = computed(() => defaultFilters.value.age.from);
    const maxAgeValue = computed(() => defaultFilters.value.age.to);
    const filterAge = computed(() => filters.value?.age && { from: filters.value.age.from, to: filters.value.age.to });

    const model = reactive<Model>({
      age: filterAge.value || { from: minAgeValue.value, to: maxAgeValue.value },
      gender: filters.value?.gender || defaultFilters.value.gender,
    });
    const minAgeRef = ref();
    const maxAgeRef = ref();

    const rules = reactive({
      minAge: (v: Nullable<string | number>) =>
        (
          isNumber(v) && v as number >= minAgeValue.value
          && v as number <= maxAgeValue.value
          && v as number <= model.age.to
        ) || t('rule.incorrect'),
      maxAge: (v: Nullable<string | number>) =>
        (
          isNumber(v) && v as number <= maxAgeValue.value
          && v as number >= minAgeValue.value
          && v as number >= model.age.from
        ) || t('rule.incorrect'),
    });

    function validate() {
      if (!minAgeRef.value || !maxAgeRef.value) return false;

      const minAgeValidate = minAgeRef.value.validate();
      const maxAgeValidate = maxAgeRef.value.validate();

      if (!minAgeValidate) {
        minAgeRef.value.focus();
      } else if (!maxAgeValidate) {
        maxAgeRef.value.focus();
      }

      return minAgeValidate && maxAgeValidate;
    }

    function onSubmit() {
      if (validate()) {
        store.commit(`${USER_STORE_KEY}/${UsersMutation.ClearFilter}`, null);
        store.commit(`${USER_STORE_KEY}/${UsersMutation.SetFilter}`, {
          ...model,
        });
        emit('submit');
      }
    }

    const profile = computed<Nullable<IProfile>>(() => useUsersGetter(UsersGetter.Profile));

    const lookingForTitle = computed(() => {
      if (!isAuth.value) return t('pages.main.lookingFor.title');
      if (profile.value?.oppositeGender === Sex.Female) return t(`pages.main.lookingFor.${Sex.Female}`);
      if (profile.value?.oppositeGender === Sex.Male) return t(`pages.main.lookingFor.${Sex.Male}`);
      return '';
    });

    function handleSelector(gender: Sex) {
      model.gender = gender;
    }

    return {
      isAuth,
      model,
      minAgeRef,
      maxAgeRef,
      rules,
      lookingForTitle,
      onSubmit,
      handleSelector,

      TxtType,
      BtnType,
      Sex,
    };
  },
});

export default MainPageSearch;
