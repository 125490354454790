<template>
  <article class="metapage-card">
    <h2 class="metapage-card__title">
      {{ title }}
    </h2>

    <div
      class="metapage-card__content"
      v-html="sanitizedContent"
    />

    <button
      :class="['metapage-card__button', isActive && 'metapage-card__button-active']"
      @click="toggle"
    >
      {{ isActive ? $t('pages.blog.readMoreHide') : $t('pages.blog.readMore') }}

      <icon
        :type="IconType.Contour"
        :name="IconName.ArrowUncover"
        :size="Size.Xs"
      />
    </button>
  </article>
</template>

<script lang="ts" src="./metaPageCard.ts" />
<style lang="scss" src="./metaPageCard.scss" />
