import { computed, defineComponent, onMounted, ref } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { StorageKeys, storageService } from 'storage';
import { useStore } from 'vuex';
import { IProfile } from 'api/users/models/user';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import IncognitoPopup from '../IncognitoPopup';

const INCOGNITO_SHOWS_INTERVAL = parseInt(process.env.VUE_APP_INCOGNITO_SHOWS_INTERVAL || '0', 10) * 1000;

const IncognitoAlert = defineComponent({
  components: {
    IncognitoPopup,
  },
  setup() {
    const textRef = ref();
    const isShow = ref(false);
    const store = useStore();
    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const popupShow = ref(false);

    onMounted(() => {
      if (profile.value?.incognito_until) {
        return;
      }

      const date = storageService.getItem(StorageKeys.IncognitoAlert);
      if (!date) {
        isShow.value = true;
        return;
      }
      const parsed = new Date(date);

      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      if (!(parsed instanceof Date) || isNaN(parsed)) {
        storageService.removeItem(StorageKeys.IncognitoAlert);
      }

      if (parsed.getTime() < Date.now() - INCOGNITO_SHOWS_INTERVAL) {
        isShow.value = true;
      }
    });

    function hideAlert() {
      isShow.value = false;
      storageService.setItem(StorageKeys.IncognitoAlert, new Date().toISOString());
    }

    function onBought() {
      isShow.value = false;
    }

    return {
      isShow,
      textRef,
      hideAlert,
      popupShow,
      onBought,

      IconName,
      IconType,
      Size,
    };
  },
});

export default IncognitoAlert;
